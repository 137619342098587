/**
* ----------------------------------------------
* CODES
* ----------------------------------------------
*
*/
$gradient-overlay: linear-gradient(to right, #7202bb 2%, #3264f5 82%);

/**
* ----------------------------------------------
* Blogs css v2
* ----------------------------------------------
*/
.blog-v2 {
  .blog-body {
    padding: 1.5rem;
    box-shadow: 0 0 60px 10px rgba(220, 220, 220, 0.3);
    background-color: $white;
  }
  // Blog meta data
  .blog-meta {
    display: flex;
    margin: 1rem 0;
    li {
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      @include border(null, 1px, solid, $border-color);
      padding: 0 8px;
      color: $theme-color-3;
      border-radius: 12.5px;
      line-height: 25px;
      font-weight: 500;
      font-size: px-rem-converter($grid-title-font-size);
      i {
        margin-right: 0.4rem;
        color: $default-color;
      }
      span {
        vertical-align: middle;
      }
    }
  }
  // Blog title
  .blog-title {
    display: block;
    text-transform: none;
    &:hover {
      h6 {
        color: $theme-color-3;
      }
    }
  }
  //Blog description
  .blog-description {
    margin-bottom: 1rem;
  }
}

/**
* ----------------------------------------------
* Blogs css v3
* ----------------------------------------------
*/
.blog-v3 {
  .blog-body {
    padding: 1.5rem;
    box-shadow: 0 0 60px 10px rgba(220, 220, 220, 0.3);
    width: 90%;
    margin: -50px auto 0;
    position: relative;
    background-color: $white;
  }
  //Blog meta data
  .blog-meta {
    display: flex;
    margin: 1rem 0;
    li {
      margin-right: 0.75rem;
      display: flex;
      align-items: center;
      color: $theme-color-3;
      border-radius: 12.5px;
      line-height: 25px;
      font-weight: 500;
      i {
        margin-right: 0.5rem;
        color: $default-color;
      }
      span {
        vertical-align: middle;
      }
    }
  }
  //Blog title
  .blog-title {
    display: block;
    text-transform: none;
    &:hover {
      h6 {
        color: $theme-color-3;
      }
    }
  }
  //Blog description
  .blog-description {
    margin-bottom: 1rem;
  }
}

/**
* ----------------------------------------------
* Blog sidebar css
* ----------------------------------------------
*/

/**
* ----------------------------------------------
* Widget css v1
* ----------------------------------------------
*/
.widget {
  padding: 30px;
  @include border(null, 1px, solid, $border-color);
  box-shadow: 0 0 60px 10px rgba(220, 220, 220, 0.3);
  border-radius: 4px;
  margin-bottom: 40px;
  .widget-title {
    letter-spacing: 1px;
    padding-bottom: 0.75rem;
    margin-bottom: 1.5rem;
    position: relative;
    &:before {
      width: 50px;
      @include position(absolute, null, null, 0, 0);
      margin: 0 auto;
      height: 2px;
      @include gradient($gradient-overlay);
      content: "";
    }
  }
}

/**
* ----------------------------------------------
* widget search
* ----------------------------------------------
*/
.widget-search {
  .input-group {
    align-items: center;
    .btn {
      margin: 0;
      min-width: auto;
      padding: 0.5rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &:hover {
        box-shadow: none;
      }
    }
    .form-control {
      padding: 0.375rem 0.75rem 0.375rem 0;
      height: calc(2.5rem + 1px);
    }
  }
}

/**
* ----------------------------------------------
* widget category list
* ----------------------------------------------
*/
.widget-category-list {
  li {
    margin-bottom: 0.75rem;
    a {
      display: flex;
      justify-content: space-between;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/**
* ----------------------------------------------
* widget recent post
* ----------------------------------------------
*/
.widget-post {
  .media {
    margin-bottom: 15px;
    img {
      border-radius: 4px;
    }
    .media-body {
      margin-left: 0.75rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .media-body {
    a {
      display: block;
      margin-bottom: 0.5rem;
    }
    li {
      display: inline-block;
      margin-right: 0.4rem;
      @include border(null, 1px, solid, $border-color);
      border-radius: 12.5rem;
      padding: 0 8px;
      line-height: 25px;
      i {
        margin-right: 0.25rem;
      }
      span {
        color: $theme-color-3;
      }
    }
  }
}

/**
* ----------------------------------------------
* widget instagram feeds
* ----------------------------------------------
*/
.insta-feeds {
  display: flex;
  margin: 0 -5px;
  flex-wrap: wrap;
  li {
    margin-bottom: 10px;
    padding: 0 5px;
    width: 33.33%;
    max-width: 33.33%;
    display: block;
    border-radius: 4px;
    overflow: hidden;
    a {
      display: block;
      @include transition(all 0.3s);
      img {
        border-radius: 4px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

/**
* ----------------------------------------------
* widget tags
* ----------------------------------------------
*/
.widget-tags {
  li {
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
  }
}
