/*
* @description Set transition
* Pass transition single properties E.g @include transition(border 0.3s ease-in-out) => transition: border 0.3s ease-in-out; -webkit-transition: border 0.3s ease-in-out;
*/
@mixin transition($value){
    transition: $value;
    -webkit-transition: $value;
}

/*
* @description Set transform
* Pass transform value E.g @include transform(scale(0.3)) => transform: scale(0.3); -webkit-transform: scale(0.3);
*/
@mixin transform($value){
    transform: $value;
    -webkit-transform: $value;
}

/*
* @description Set delay in transition
* Pass transition delay E.g @include transition-delay(1s) => transition-delay: 1s; -webkit-transition-delay: 1s.
*/
@mixin transition-delay($value){
    transition-delay: $value;
    -webkit-transition-delay: $value;
}

/*
* @description This mixin is used to create position
* Pass position like absolute, fixed etc. and give top, bottom, left, right, index.
* All params are optional, After passing all things it's generate the css.
* E.g @include position(absolute, 10px, 10px, 10px, 10px, 1) => position: absolute;top:10px;bottom:10px;left:10px;right:10px;index:1;
* Another E.g @include position(absolute, 10px, 10px, 10px, 10px) => position: absolute;top:10px;bottom:10px;left:10px;right:10px;
*/
@mixin position($pos, $top:null, $right:null, $bottom:null, $left:null, $index:null) {
    position: $pos;
    top: $top;
    left: $left;
    right: $right;
    bottom: $bottom;
    z-index: $index
}

/*
* @description This mixin is used to create border
* Pass direction like top, bottom, left, right and give width of the border, style of border like solid, dashes etc, color of the border and pass null if you want border all the sides. E.g @include border(null, 1px, solid, #000) => border: 1px solid #000;
* Another E.g @include border(left, 1px, solid, #000) => border-left: 1px solid #000;
*/
@mixin border($direction, $width, $style, $color) {
    @if($direction==left) {
        border-left: $width $style $color;
    }
    @else if($direction==right) {
        border-right: $width $style $color;
    }
    @else if($direction==top) {
        border-top: $width $style $color;
    }
    @else if($direction==bottom) {
        border-bottom: $width $style $color;
    }
    @else {
        border: $width $style $color;
    }
}

/*
* @description This mixin is used to create grediant button
* Pass grediant value, clip type, clip type 2, origin E.g @include gradient($gred) => background-image: $grad; ---> used to include grediant background
* Another E.g @include gradient($gred,padding-box, border-box, border-box) => background-image: $grad;background-clip: padding-box, border-box; background-origin: border-box;---> this used to create button gred.
*/ 
@mixin gradient($grad,$clip1:null,$clip2:null,$origin:null){
    background-image: $grad;
    background-clip: $clip1, $clip2;
    background-origin: $origin;
}

/*
* @description This mixin is used to create text grediant
* Pass grediant value E.g @include text-gradient($gred) => background-image: $grad; -webkit-text-fill-color: transparent; -webkit-background-clip: text;
*/
@mixin text-gradient($gred){
    @include gradient($gred);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

/*
* @description This mixin is used to create greyscale the image or others
* Pass greyscale percentage E.g @include grayScale(100%) => filter: grayscale(100%);-webkit-filter: grayscale(100%);
*/
@mixin grayScale($percent){
    filter: grayscale($percent);
    -webkit-filter: grayscale($percent);
}

/*
* @description This mixin is used to create animation
* Pass animation name percentage E.g @include animation(slideIn) => ;animation-name: slideIn; -webkit-animation-name: slideIn
*/
@mixin animation($name){
    -webkit-animation-name: $name;
    animation-name: $name;
}

/*
* @description This mixin is used to create animation duration
* Pass animation name percentage E.g @include animation-duration(1s) => animation-duration: 1s; -webkit-animation-duration: 1s;
*/
@mixin animation-duration($value){
    -webkit-animation-duration: $value;
    animation-duration: $value;
}

/*
* @description This mixin is used to create animation function 
* Pass animation timing function E.g @include animation-function(cubic-bezier(0.81, 0.15, 0.26, 0.99)) => animation-timing-function: cubic-bezier(0.81, 0.15, 0.26, 0.99); -webkit-animation-timing-function: cubic-bezier(0.81, 0.15, 0.26, 0.99);
*/
@mixin animation-function($func){
    -webkit-animation-timing-function: $func;
    animation-timing-function: $func;
}